import { useState, useCallback } from "react"
/** @jsx jsx */
import { css, jsx } from "@emotion/core"

import { socket } from "../../store"

const ChatInput = ({ chatId }) => {
  const [value, setValue] = useState("")
  const send = useCallback(() => {
    socket.sendChat(value, chatId)
    setValue("")
  }, [value, setValue, chatId])
  const change = useCallback(ev => setValue(ev.target.value), [])
  const keyDown = useCallback(
    ev => {
      if (ev.key === "Enter") {
        send()
      }
    },
    [send]
  )
  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
      `}
    >
      <input
        type="text"
        value={value}
        onChange={change}
        onKeyDown={keyDown}
        css={theme => css`
          appearance: none;
          pointer-events: all;
          font: inherit;
          color: inherit;
          border: 2px solid currentColor;
          background: inherit;
          text-shadow: -1px 0 0 #000, 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000;
          box-sizing: border-box;
          width: 100%;

          &:focus,
          &:active {
            outline: 2px solid ${theme.color.special};
          }
        `}
      />
      <button
        disabled={!value.length}
        css={theme => [
          theme.resetButton,
          css`
            cursor: pointer;
            background: inherit;
            color: inherit;
            margin: -2px 0;
            display: block;
            padding: 7px 4px 0;
            height: 100%;
            box-sizing: border-box;
            pointer-events: all;
          `,
        ]}
        onClick={send}
      >
        Ok
      </button>
    </div>
  )
}
export default ChatInput
