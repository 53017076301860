import { useState, useEffect } from "react"
import { useObserver } from "mobx-react"
/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import { AnimatePresence, motion } from "framer-motion"

import { chatMessages } from "../../store"

const ChatOutput = ({ chatId }) => {
  const [count, setCount] = useState(0)
  useEffect(() => {
    const timeout = setTimeout(() => setCount(count + 1), 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [count])

  return useObserver(() => (
    <div
      css={css`
        position: absolute;
        bottom: 100%;
        padding-bottom: 1em;
        text-align: left;
        font-size: 80%;
      `}
    >
      <AnimatePresence>
        {chatMessages
          .filter(m => chatId === m.id)
          .map((m, i) => {
            const age = new Date().getTime() - m.time
            if (age > 30000) return null
            return (
              <motion.div
                key={i}
                layoutTransition
                initial={{ y: "1em", opacity: 0, scale: 0.001 }}
                exit={{ y: "-100%", opacity: 0 }}
                animate={{ y: "0em", opacity: 1, scale: 1 }}
                css={css`
                  margin: 0.2em 0;
                `}
              >
                <span
                  css={theme =>
                    css`
                      color: ${theme.color.special};
                      font-size: 80%;
                      margin-right: 1em;
                    `
                  }
                >
                  {m.nick}
                </span>
                <span>{m.value}</span>
              </motion.div>
            )
          })}
      </AnimatePresence>
    </div>
  ))
}

export default ChatOutput
