/** @jsx jsx */
import { css, jsx } from "@emotion/core"

const LabelValue = ({ label, value, textAlign, children, ...props }) => (
  <div
    css={css`
      text-align: ${textAlign || "left"};
    `}
    {...props}
  >
    <div
      css={css`
        font-size: 70%;
        text-transform: lowercase;
      `}
    >
      {label}
    </div>
    {typeof value !== "undefined" && (
      <div
        css={css`
          padding: 0.4em 0;
        `}
      >
        {value}
      </div>
    )}
    {children}
  </div>
)

export default LabelValue
